import React, { ReactNode } from 'react';

import ErrorText from '@/Components/Input/ErrorText';
import Label from '@/Components/Input/Label';

export type FormProps = {
  className?: string;
  errorText?: string;
  helperText?: string;
  label?: string;
  required?: boolean;
};

const Form: React.FC<FormProps & { children: ReactNode }> = (props) => {
  return (
    <div className={`flex flex-col ${props.className}`}>
      {props.label && (
        <Label className="mb-1" label={props.label} required={props.required} />
      )}

      <div className="relative flex items-center">{props.children}</div>

      {props.helperText && (
        <span className="text-start text-sm text-gray-500 mt-1">
          {props.helperText}
        </span>
      )}

      {props.errorText && (
        <ErrorText className="mt-1" errorText={props.errorText} />
      )}
    </div>
  );
};

export default Form;
